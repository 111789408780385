import css from './advice.module.css';
import Header from './header';
import { useEffect, useState } from 'react';
import axios from 'axios';
import AdviceItem from './adviceItem';
import { RightIcon } from './RightIcon';
import { LeftIcon } from './LeftIcon';
import HashtagPopup from './category';


function Advice() {
    const [isTransitioningRight, setIsTransitioningRight] = useState(false);
    const [isTransitioningLeft, setIsTransitioningLeft] = useState(false);


    const [advices, setAdvices] = useState([])
    const [currAdvice, setCurrAdvice] = useState(0);
    const [currentCategory, setCurrentCategory] = useState('#random');

    let timeoutId = null;

    const handleCategoryChange = (category) => {
        console.log('Current Category:', category);
        setCurrentCategory(category)
    };

    const fetchAdvice = () => {
        const params = {
            category: currentCategory.replace('#', ''),
        };
        // console.log('Current Category for advice:', currentCategory);
        axios.get('/get/advice', {params}).then((response) => {
            setAdvices((prev) => [...prev, response.data]);
        });
    };

    useEffect(() => {
        fetchAdvice();
    }, [])


    useEffect(() => {
        if (currAdvice === advices.length - 1) {
            fetchAdvice();
        }
    }, [currAdvice, advices])

    const onNextAdviceClicked = () => {
        if (isTransitioningRight || isTransitioningLeft) return;
        if (advices.length - 1 === currAdvice) return;

        const nextAdviceIndex = currAdvice + 1;
        setIsTransitioningRight(true);

        const image = new Image();
        image.src = advices[nextAdviceIndex].url;
        image.decode().then();
        setCurrAdvice(nextAdviceIndex);
        timeoutId = setTimeout(() => {
            setIsTransitioningRight(false);
        }, 3000);
    }

    const onPrevAdviceClicked = () => {
        if (isTransitioningRight || isTransitioningLeft) return;
        setIsTransitioningLeft(true);
        setCurrAdvice(currAdvice-1);
        timeoutId = setTimeout(() => {
            setIsTransitioningLeft(false);
        }, 3000);
    }

    return (
        <div className={`${css.root}`}>
          <Header nextAdvice={onNextAdviceClicked}/>
          <div className={css.gradientOverlay}>

              { isTransitioningLeft && (
                  <>
                      <div className={`${css.adviceItem} ${isTransitioningLeft ? css.slideInLeft : ''}`} id="last-advice">
                          <AdviceItem advice={advices[currAdvice]}/>
                      </div>
                      <div className={`${css.adviceItem} ${isTransitioningLeft ? css.slideOutRight : ''}` } id="current-advice">
                          <AdviceItem advice={advices[currAdvice+1]} />
                      </div>
                  </>
              )
              }


              { isTransitioningRight && (
                  <>
                      <div className={`${css.adviceItem} ${isTransitioningRight ? css.slideOutLeft : ''}`} id="last-advice">
                          <AdviceItem advice={advices[currAdvice-1]}/>
                      </div>
                      <div className={`${css.adviceItem} ${isTransitioningRight ? css.slideInFromRight : ''}` } id="current-advice">
                          <AdviceItem advice={advices[currAdvice]} />
                      </div>
                  </>
              )
              }

              { (!isTransitioningRight && !isTransitioningLeft) && (
              <div className={`${css.adviceItem}` } id="current-advice">
                  <AdviceItem advice={advices[currAdvice]} />
              </div>
                  )}
          </div>

          <HashtagPopup onCategoryChange={handleCategoryChange}/>
          <div className={css.nextAdvice} onClick={onNextAdviceClicked}>
              <RightIcon/>
          </div>

          {currAdvice > 0 && (
                <div className={css.prevAdvice} onClick={onPrevAdviceClicked}>
                    <LeftIcon/>
                </div>
          )}
        </div>
    );
}

export default Advice;
