import { useEffect, useRef } from 'react';
import css from './adviceItem.module.css';

function AdviceItem({advice}) {
  const imgRef = useRef();

  useEffect(() => {
    if (imgRef.current && advice?.url) {
      imgRef.current.src = advice.url
    }
  }, [advice?.url])

  if (!advice) return <></>

  return (
    <div className={css.root}>
        <div className={css.gradient}/>
        <img className={css.img} ref={imgRef} src={advice.url}/>

      <div className={css.phrase}>
        {advice.phrase.toUpperCase().replace(/"/g, '').replace(/«/g, '').replace(/»/g, '')}
      </div>
    </div>
  );
}

export default AdviceItem;
