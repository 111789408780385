import React, { useState } from 'react';
import css from './advicecategory.module.css'

let Categories = ['#random', '#разработка', '#мотивация', '#успех', '#деньги', '#философия', '#frontend', '#backend']

const countPerLine = 3

const HashtagPopup = ({ onCategoryChange }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentCategory, setCurrentCategory] = useState('#random');

    const openModal = () => {
        if (isModalOpen) {
            setIsModalOpen(false);
            return
        }
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const setCategory = (category) => {
        console.log(category)
        setCurrentCategory(category)
        closeModal()
        onCategoryChange(category);
    }

    let idx = -1

    return (
        <div>
            <div className={css.selectCategory} >
                <div className={css.selectCategoryText} >ЭТОТ СОВЕТ ИЗ КАТЕГИРИИ </div>
                <div className={css.category} onClick={openModal}> {currentCategory} </div>
            </div>
            {isModalOpen && (
                <div className={css.blurBackground}>
                <div className={css.modal}>
                    <span className={css.close} onClick={closeModal}>&times;</span>
                    <div className={css.modalHead}>КАТЕГОРИИ СОВЕТОВ</div>
                    <div  className={css.categoryContainer}>

                            {Array.from({ length: Math.floor(Categories.length / countPerLine)+1}, (_, rowNumber) => {
                                return (
                                    <table className={css.row}>
                                        <tbody>
                                    <tr key={rowNumber}>
                                        {Array.from({ length: countPerLine*2 -1}, (_, position) => {
                                            if (Categories.length === idx+1) {
                                               return
                                            }
                                            if (rowNumber % 2 === 0) {
                                                if (position % 2 === 0) {
                                                    idx++;
                                                    return (
                                                        <td className={css.categoryItem}  onClick={(event) => setCategory(event.target.textContent)}>{Categories[idx]}</td>
                                                    );
                                                } else {
                                                    return (<td className={css.emptyCell}/>);
                                                }
                                            } else {
                                                if (position % 2 === 1) {
                                                    idx++;
                                                    return (
                                                        <td className={css.categoryItem} onClick={(event) => setCategory(event.target.textContent)}>{Categories[idx]}</td>
                                                    );
                                                } else {
                                                    return (<td className={css.emptyCell}/>);
                                                }
                                            }
                                        })}
                                    </tr>
                                        </tbody>
                                    </table>
                                );
                            }
                   )}

                    </div>
                </div>
                </div>
            )}
        </div>
    );
};

export default HashtagPopup;
