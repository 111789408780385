import css from './advice.module.css';
import domtoimage from 'dom-to-image';
import { Link } from 'react-router-dom';

function Header({nextAdvice}) {
  return (
    <div className={css.header}>
      <div className={css.download} onClick={takeScreenshot}>СКАЧАТЬ СОВЕТ </div>
      <Link to={'https://t.me/misli_cyber_stathama'}>
        <div className={css.statham}>CYBER STATHAM</div>
      </Link>
        <div className={css.getAdvice} onClick={nextAdvice}> ЕЩЕ СОВЕТ </div>
    </div>
  );
}

export default Header;


const takeScreenshot = () => {
    const targetElement = document.getElementById("current-advice");
    let scale = 2;

        domtoimage.toPng(targetElement, {
            width: window.innerWidth * scale,
            height: window.innerHeight * scale,
            style: { transform: 'scale(' + scale + ')', transformOrigin: 'top left' }
        })
            .then(function (dataUrl) {
              console.log('dataUrl')
                const screenshotImage = new Image();
                screenshotImage.crossOrigin = "anonymous";
                screenshotImage.src = dataUrl;

                const overlayImage = new Image();
                overlayImage.src = 'https://my-trash.sfo3.cdn.digitaloceanspaces.com/qr.jpg'; // Замените на URL вашего наложения
                overlayImage.crossOrigin = "anonymous";

                Promise.all([
                    new Promise((resolve, reject) => {
                        screenshotImage.onload = resolve;
                        screenshotImage.onerror = reject;
                    }),
                    new Promise((resolve, reject) => {
                        overlayImage.onload = resolve;
                        overlayImage.onerror = reject;
                    })
                ])
                    .then(() => {
                        const overlayWidth = 200; // Новая ширина изображения наложения
                        const overlayHeight = 200; // Новая высота изображения наложения

                        const resizedOverlayDataUrl = resizeImage(overlayImage, overlayWidth, overlayHeight);

                        const canvas = document.createElement('canvas');
                        canvas.width = screenshotImage.width;
                        canvas.height = screenshotImage.height;
                        const context = canvas.getContext('2d');

                        context.drawImage(screenshotImage, 0, 0);

                        const resizedOverlayImage = new Image();
                        resizedOverlayImage.src = resizedOverlayDataUrl;
                        resizedOverlayImage.onload = function () {
                            context.drawImage(resizedOverlayImage, canvas.width - resizedOverlayImage.width - 100, canvas.height - resizedOverlayImage.height - 100); // Замените x и y на координаты, где хотите наложить изображение

                            const finalDataUrl = canvas.toDataURL('image/png');

                            const link = document.createElement('a');
                            link.href = finalDataUrl;
                            link.download = 'advice_with_overlay.png';
                            link.click();
                        };
                    })
                    .catch(function (error) {
                        console.error('Error while overlaying image:', error);
                    });
            })
            .catch(function (error) {
                console.error('Error while taking screenshot:', error);
            })}

function resizeImage(image, newWidth, newHeight) {
    const canvas = document.createElement('canvas');
    canvas.width = newWidth;
    canvas.height = newHeight;
    const context = canvas.getContext('2d');

    context.drawImage(image, 0, 0, newWidth, newHeight);

    return canvas.toDataURL('image/png');
}
