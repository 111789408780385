import React from 'react';
import css from './loader.module.css';

const Loader = () => {
    return (
        <div className={css.loader}>
            <div className={css.shadow}></div>
            <div className={css.head}></div>
            <div className={css.text}>LOADING</div>
        </div>
    );
};

export default Loader;
