import css from './App.module.css';
import DesktopAdvice from './components/desktop';
import Loader from './components/loader';
import React, { useState, useEffect } from 'react';


function App() {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 3000);
    }, []);

    return (
    <div className={css.app}>
        {isLoading ? <Loader /> : <DesktopAdvice />}
    </div>
  );
}

export default App;
